<template>
  <div class="flex flex-col min-h-screen bg-gray-100">
    <div v-if="user" class="flex-grow pb-14 lg:p-8">
      <div class="px-6 mx-auto w-full max-w-xl">
        <h1 class="pl-0 page-headline">Settings</h1>
        <div class="px-4 pt-1 pb-1 mb-4 bg-white rounded-xl">
          <div class="flex">
            <h3 class="flex-1 my-2">Account</h3>
            <div class="relative w-10">
              <loading v-show="loading" size="small"/>
            </div>
          </div>
          <div class="form-field">
            <label for="email">Email</label>
            <input v-if="user" name="email" type="email" placeholder="Email Address" v-model="user.email" @change="saveSetting">
            <input v-else name="email" type="email" disabled>
          </div>
          <div class="form-field">
            <label for="name">Display Name</label>
            <input v-if="user" name="name" type="text" placeholder="Display Name" v-model="user.displayName" @change="saveSetting">
            <input v-else name="name" type="text" disabled>
          </div>
          <div class="mt-10 form-field">
            <div class="block">
              <button type="button" class="block py-3 px-0 w-full leading-5 btn outline red" :disabled="isLogoutPending" @click="handleLogout">
                <div v-if="isLogoutPending" class="relative h-5">
                  <loading color="white" size="small"></loading>
                </div>
                <span v-else>Log Out</span>
              </button>
            </div>
          </div>
        </div>
        <div class="px-4 pt-1 pb-3 mb-4 bg-white rounded-xl">
          <h3 class="my-2">Desk</h3>
          <form-toggle
            class="leading-7 text-gray-700 lg:leading-8"
            v-model="deskShowTomorrowTasks" label="Show Tomorrow Tasks"/>
          <form-toggle
            class="leading-7 text-gray-700 lg:leading-8"
            v-model="deskCompactPinnedNotes" label="Compact Pinned Notes"/>
          <form-toggle
            class="leading-7 text-gray-700 lg:leading-8"
            v-model="deskShowRecentNotes" label="Show Recent Notes"/>
          <form-toggle
            class="leading-7 text-gray-700 lg:leading-8"
            v-model="deskCompactRecentNotes" label="Compact Recent Notes"/>
        </div>
        <div class="px-4 pt-1 pb-3 mb-10 bg-white rounded-xl">
          <h3 class="my-2">Calendar</h3>
          <form-toggle
            class="leading-7 text-gray-700 lg:leading-8"
            v-model="calendarShowWeekend" label="Show weekends on Calendar"/>
          <form-toggle
            class="leading-7 text-gray-700 lg:leading-8"
            v-model="calendarShowTasks" label="Show Tasks on Calendar"/>
          <form-toggle
            class="leading-7 text-gray-700 lg:leading-8"
            v-model="calendarShowNotes" label="Show Notes on Calendar"/>
        </div>
        <p v-if="error">{{ error }}</p>
      </div>
    </div>
    <div v-else class="relative flex-grow w-full h-full">
      <loading/>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { debounce } from 'lodash-es'
import Settings from '@/mixins/Settings'
import Loading from '@/components/Loading'
import FormToggle from '@/components/FormToggle'

export default {
  name: 'Settings',
  components: {
    Loading,
    FormToggle
  },
  mixins: [
    Settings
  ],
  data() {
    return {
      loading: false,
      error: null
    }
  },
  watch: {
    userPending(val) {
      if (val) {
        this.loading = val
      } else {
        this.debounceSetLoading(val)
      }
    }
  },
  computed: {
    ...mapState('auth', ['isLogoutPending']),
    ...mapGetters('auth', ['user']),
    ...mapGetters('users', { isUserPending: 'isPendingById' }),
    userPending() {
      return this.user && this.isUserPending(this.user._id)
    },
    deskShowTomorrowTasks: {
      get() { return this.settings.deskShowTomorrowTasks },
      set(value) { this.writeSettings('deskShowTomorrowTasks', value) }
    },
    deskCompactPinnedNotes: {
      get() { return this.settings.deskCompactPinnedNotes },
      set(value) { this.writeSettings('deskCompactPinnedNotes', value) }
    },
    deskCompactRecentNotes: {
      get() { return this.settings.deskCompactRecentNotes },
      set(value) { this.writeSettings('deskCompactRecentNotes', value) }
    },
    deskShowRecentNotes: {
      get() { return this.settings.deskShowRecentNotes },
      set(value) { this.writeSettings('deskShowRecentNotes', value) }
    },
    calendarShowTasks: {
      get() { return this.settings.calendarShowTasks },
      set(value) { this.writeSettings('calendarShowTasks', value) }
    },
    calendarShowWeekend: {
      get() { return this.settings.calendarShowWeekend },
      set(value) { this.writeSettings('calendarShowWeekend', value) }
    },
    calendarShowNotes: {
      get() { return this.settings.calendarShowNotes },
      set(value) { this.writeSettings('calendarShowNotes', value) }
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    handleLogout() {
      this.$store.commit('tasks/clearAll')
      this.$store.commit('events/clearAll')
      this.$store.commit('notes/clearAll')
      this.$store.commit('folders/clearAll')
      this.$store.commit('users/clearAll')
      this.$store.commit('auth/setLogoutPending')
      setTimeout(() => {
        this.logout().then(() => {
          localStorage.clear()
          this.$router.push('/login')
        }).catch(error => {
          console.error('logout: ' + error)
        })
      }, 500)
    },
    saveSetting() {
      this.user.patch()
    },
    debounceSetLoading: debounce(function(val) {
      this.loading = val
    }, 500)
  }
}
</script>
